import { Card, Carousel } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
import { Image } from "react-bootstrap";
import CountUp from "react-countup";
import Basicos6 from "../assets/Basicos1.jpg";
import Problema1 from "../assets/Ing2.jpg";
import Problema2 from "../assets/Problema2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBookmark,
  faChevronLeft,
  faChevronRight,
  faHeart,
  faQuoteLeft,
  faStar,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import facebookReviewsJSON from "../assets/FacebookReviews.json";
import StarRatingComponent from "react-star-rating-component";
import FacebookReviewsCarousel from "../components/FacebookReviewsCarousel";
import LoaderButton from "../components/LoaderButton";
import { useHistory } from "react-router-dom";
export default function Home() {
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <div className="Home m-0 p-0 row">
      <div className="container mt-4">
        <div className="row mb-5 mx-md-0 mx-2  ">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center mb-5">
            <h1 className="mb-5 Title">Cursos Exámenes de Admisión USAC</h1>
            <p className="lead Description">
              Prepárate de la mejor manera para tus pruebas de ingreso a la
              universidad.
            </p>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <Image src={Basicos6} roundedCircle fluid />
          </div>
          <div className="col-12 col-md-4 d-flex flex-column  justify-content-center align-items-center mb-5 ">
            <FontAwesomeIcon size="4x" icon={faHeart} />
            <CountUp
              end={68000}
              start={10000}
              duration={2}
              className="Title"
              suffix=" +"
              separator=" "
            />
            <h3>Me Gusta</h3>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column  justify-content-center align-items-center mb-5 ">
            <FontAwesomeIcon size="4x" icon={faStar} />
            <CountUp
              decimals={1}
              decimal="."
              end={4.7}
              start={1}
              duration={4}
              className="Title"
            />
            <h3>Calificación</h3>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column  justify-content-center align-items-center mb-5 ">
            <FontAwesomeIcon size="4x" icon={faUserGraduate} />
            <CountUp
              end={2500}
              start={500}
              duration={2}
              className="Title"
              suffix=" +"
            />
            <h3>Estudiantes</h3>
          </div>
        </div>
        <div className="mx-md-0 mx-2  mb-5 ContainerView row d-flex flex-row align-items-center">
          <div className="col-12 col-md-6 ">
            <Image src={Problema1} rounded fluid />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center ">
            <h1 className="pl-md-4 mb-5 Title">Problemas de Temarios</h1>
            <p className="pl-md-4 lead Description">
              Te explicamos paso a paso cómo resolver problemas de dificultad de
              examen, tomados de temarios utilizados para las pruebas en la
              universidad.
            </p>
          </div>
        </div>
        <div className="mb-5 mx-md-0 mx-2 ContainerView row  d-flex flex-row align-items-center">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 className="pr-md-4 mb-5 Title">
              Clases Con Explicaciones Claras
            </h1>
            <p className="pr-md-4 lead Description">
              Buscamos explicar los temas y solución de problemas de la forma
              más concisa posible, hacemos énfasis en los errores más comunes.{" "}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <Image src={Problema2} rounded fluid />
          </div>
        </div>
        <div className="mx-0  mt-3 ContainerView ">
          <FacebookReviewsCarousel />
        </div>
        <div className="mb-5 mx-md-0 mx-2 ContainerView row  d-flex flex-row align-items-center">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 className="pr-md-4 mb-5 Title">Listado de Cursos</h1>
            <p className="pr-md-4 lead Description">
              Si deseas más información visita nuestro catálogo de cursos.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <LoaderButton
              block
              size="lg"
              className="mx-md-5"
              variant="success"
              onClick={() => history.push("/cursos")}
            >
              Ver Cursos
            </LoaderButton>
          </div>
        </div>
      </div>
    </div>
  );
}
