import React from "react";
import { Spinner } from "react-bootstrap";

export default function LoadingPage() {
  return (
    <div>
      <div className="container  py-5 px-5 my-5 d-flex flex-row justify-content-center align-items-center">
        {" "}
        <Spinner className="mt-5 " animation="grow" variant="primary" />{" "}
      </div>
    </div>
  );
}
