import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import Vimeo from "@u-wave/react-vimeo";
import { Card } from "react-bootstrap";

export default function VideoScrollList({ videos = [] }) {
  function renderVideos() {
    return videos.map((videoObject, i) => (
      <div
        className={`col-md-5 col-sm-12 pb-3 pr-2`}
        style={{ minWidth: "280px" }}
        key={i}
      >
        <Card className="p-0">
          <Card.Body className="p-0">
            <Vimeo video={videoObject.video_id} responsive className="p-0" />
          </Card.Body>
        </Card>
      </div>
    ));
  }

  return (
    <ScrollContainer
      className="row flex-row flex-nowrap mt-4"
      style={{ overflowX: "auto" }}
      hideScrollbars={false}
      vertical={false}
      horizontal={true}
      activationDistance={0}
    >
      {renderVideos()}
    </ScrollContainer>
  );
}
