import React from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "franklin-ckeditor";
import { Editor } from "@tinymce/tinymce-react";
import { useAppContext } from "../libs/contextLib";
import { Controller } from "react-hook-form";

const hostName = window.location.hostname;

console.log(hostName);
const scriptURL = `${
  window.location.protocol +
  "//" +
  (hostName === "localhost" ? window.location.host : hostName)
}/tinymce/js/tinymce/tinymce.min.js`;

export default function RichTextArea({
  name,
  textareaName = "",
  readOnly,
  ...rest
}) {
  const { isDark } = useAppContext();

  return (
    <Controller
      {...rest}
      name={name}
      render={({ value, onChange }) => (
        <Editor
          value={value}
          onEditorChange={onChange}
          disabled={readOnly}
          id={`k-${isDark} ${name}`}
          key={`k-${isDark} ${name}`}
          tinymceScriptSrc={scriptURL}
          //   apiKey="xr92s33uz0dm6eh3ux6i79q9czufdfbf768c4ittq6dtpn99"
          init={{
            min_height: 100,
            autoresize_bottom_margin: 5,
            menubar: false,
            skin: isDark ? "oxide-dark" : "oxide",
            content_css: isDark ? "dark" : "",
            statusbar: false,
            language: "es_MX",
            language_url: "/langs/es_MX.js",
            external_plugins: {
              tiny_mce_wiris: "/mathtype-tinymce5/plugin.min.js",
            },
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help autoresize",
            ],
            toolbar:
              "formatselect | bold italic link | \
                      alignleft aligncenter alignright alignjustify  | \
                      bullist numlist table ",
          }}
          // tiny_mce_wiris_formulaEditorChemistry
        />
      )}
    />
  );
}

/*
  return isDark ? (
    <Editor
      apiKey="xr92s33uz0dm6eh3ux6i79q9czufdfbf768c4ittq6dtpn99"
      initialValue="<p> Negro </p>"
      init={{ ...init, skin: "oxide-dark", content_css: "dark" }}
    />
  ) : (
    <Editor
      apiKey="xr92s33uz0dm6eh3ux6i79q9czufdfbf768c4ittq6dtpn99"
      initialValue="<p> Blanco </p>"
      init={{ ...init, skin: "", content_css: "" }}
    />
  );


<CKEditor
            className="mt-5"
            editor={ClassicEditor}
            data="<p>Editor Hello</p>"
            config={{
              toolbar: [
                ["heading"],
                ["|"],
                ["bold"],
                ["italic"],
                ["link"],
                ["bulletedList"],
                ["numberedList"],
                ["|"],
                ["indent"],
                ["outdent"],
                ["|"],
                ["imageUpload"],
                ["blockQuote"],
                ["insertTable"],
                ["mediaEmbed"],
                ["undo"],
                ["redo"],
                ["|"],
                ["MathType"],
                ["ChemType"],
              ],
            }}
          />

skin: (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : ""),
content_css: (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "")

*/
