import React, { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { s3GetPublicFile } from "../../../libs/awsLib";
import EmailForm from "../../../components/EmailForm";
import { useAppContext } from "../../../libs/contextLib";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";

export const FirstIngresPage = () => {
  const [imagesMap, setImagesMap] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  async function getImages() {
    let jardinesImage = await s3GetPublicFile("images/usac_jardines.jpg");
    let cuadroExamanes = await s3GetPublicFile(
      "images/cuadro_examenes_por_carrera.jpg"
    );
    let guiaImage = await s3GetPublicFile(
      "images/guia-definitiva-ingreso-usac.PNG"
    );

    console.log(jardinesImage);
    console.log(cuadroExamanes);
    setImagesMap({ jardinesImage, cuadroExamanes, guiaImage });
  }

  useEffect(() => {
    getImages();
    if (!isAuthenticated) {
      setTimeout(() => {
        setIsModalVisible(true);
      }, 15000);
    }
  }, []);

  function onSuccessEmailForm() {
    setIsModalVisible(false);
  }

  return (
    <div className="pt-5 pb-5 container px-3 px-md-5">
      <Modal
        centered
        size="lg"
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      >
        <Modal.Body className="mx-md-5 mx-3 my-3">
          <EmailForm onSuccessCallBack={onSuccessEmailForm} />
        </Modal.Body>
      </Modal>

      <h2>Guia Primer Ingreso USAC</h2>

      <div className="row">
        <div className="col-12 col-md-8 text-justify ">
          <p className="mt-3 mt-md-5 text-justify">
            ¿Deseas ingresar a la universidad? Si es así, estás en el lugar
            correcto. En primer lugar queremos felicitarte al tomar esta
            decisión ya que eso demuestra valentía y deseo de superarse y sobre
            todo, de formar un mejor país para todos. A continuación te
            explicamos los pasos que deberás seguir en tu proceso de ingreso a
            la universidad.
          </p>
        </div>
        <div className="col-12 col-md-4 mt-3 mt-md-1  ">
          {imagesMap?.jardinesImage && (
            <Image src={imagesMap.jardinesImage} fluid rounded></Image>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 ">
          <br></br>
          <h4>1. PRUEBA DE ORIENTACIÓN VOCACIONAL</h4>
          <p className="mt-3 text-justify  ">
            Es una prueba psicométrica que evalúa los intereses de estudio para
            tu carrera universitaria. Debes inscribirte en{" "}
            <a href="https://vocacional.usac.edu.gt/" target="_blank">
              vocacional.usac.edu.gt
            </a>{" "}
            . Esta prueba no se gana ni se pierde, ya que su objetivo es
            ayudarte a tomar una decisión de la carrera que elegirás. Al
            concluir la prueba obtendrás:
          </p>
          <ol>
            <br></br>
            <li>Resultado con carreras recomendadas. </li>
            <li>
              Número de Orientación Vocacional -NOV- el cual utilizaras para
              inscribirte a las demás pruebas.
            </li>
          </ol>

          <ul>
            <li> Fechas: Marzo - Octubre </li>
          </ul>
        </div>

        <div className="col-12 mt-3 ">
          <br></br>
          <h4>2. PRUEBA DE CONOCIMIENTOS BÁSICOS -PCB-</h4>
          <p className="mt-3 text-justify ">
            Son pruebas generales para todas las carreras, debes asignarte y
            realizarlas en el SUN{" "}
            <a href="https://sun.usac.edu.gt/" target="_blank">
              sun.usac.edu.gt{" "}
            </a>{" "}
            . Dependiendo de la facultad a la que desees ingresar deberas
            asignarte de 1 a 5 exámenes (ver imagen en recursos).
          </p>
          <p className="text-justify ">
            {" "}
            Las posibles materias son: Biología, Física, Lenguaje, Matemática y
            Química, correspondientes a los ciclos Básico y Diversificado de
            nivel medio.
          </p>
          <p className="text-justify ">
            {" "}
            Por ejemplo, si deseas ingresar a la facultad de Ingeniería deberás
            asignarte Lenguaje y Física. Si deseas ingresar a Medicina deberás
            asignarte Lenguaje, Física y Química.
          </p>

          <ul>
            <li> Fechas: Junio - Octubre </li>
          </ul>
        </div>

        <div className="col-12 text-justify ">
          <br></br>
          <h5>RESULTADOS</h5>
          Estos puedes consultarlos en este sitio{" "}
          <a href="https://sun.usac.edu.gt/" target="_blank">
            sun.usac.edu.gt{" "}
          </a>{" "}
          en la opción USAC EN LINEA Resultados de PCB; si tus resultados son{" "}
          <b>Satisfactorios</b> en todas las materias que solicita la carrera de
          tu elección, deberás de ingresar a{" "}
          <a href="https://usacenlinea.usac.edu.gt/#/welcome" target="_blank">
            {" "}
            usac-enlinea.web.app{" "}
          </a>{" "}
          para generar e imprimir tu constancia; en caso de obtener resultado
          insatisfactorio puedes inscribirte a la próxima fecha de pruebas.
        </div>

        <div className="col-12 mt-3 ">
          <br></br>
          <h4>3. PRUEBAS ESPECÍFICAS</h4>
          <p className="mt-3 text-justify ">
            Estas pruebas deberas asignártelas y realizarlas en la facultad a la
            cual desees ingresar, por lo que debes consultar el sitio web de
            dicha facultad en específico. Las pruebas específicas son de mayor
            dificultad en comparación con las pruebas de conocimientos básicos,
            por lo que deberás estudiar lo más que puedas para preparte de mejor
            manera.
          </p>

          <ul>
            <li> Fechas: Agosto - Noviembre </li>
          </ul>
        </div>

        <div className="col-12 mt-3  ">
          <br></br>
          <h4>4. INSCRIPCIÓN </h4>
          <p className="text-justify mt-3">
            Si has llegado a este último paso con mucho gusto te damos un
            aplauso y para finalizar debes de ingresar al siguiente enlace:{" "}
            <a href="https://portalregistro.usac.edu.gt/" target="_blank">
              {" "}
              portalregistro.usac.edu.gt
            </a>
          </p>
          <ul className="mt-4">
            <li>
              {" "}
              USAC aún no ha publicado fechas oficiales, las que te proveemos
              son una buena aproximación y están basadas en años anteriores:{" "}
            </li>
          </ul>
        </div>

        <div className="col-12 col-md-6  mt-3 mt-md-5">
          <h4>Cursos para Estudiar</h4>
          <p className="text-justify mt-3">
            Te ofrecemos cursos para estudiar para los examenes básicos de
            diversas carreras y para las pruebas específicas de Medicina e
            Ingeniería. En todos nuestros cursos repasamos los temas más
            importantes y nos enfocamos en resolver problemas de temarios de
            pruebas de años anteriores.
          </p>
        </div>
        <div className="col-12 col-md-6 mt-3 d-flex  justify-content-center align-items-center ">
          <LoaderButton
            block
            size="lg"
            className="mx-md-5"
            variant="success"
            onClick={() => history.push("/cursos")}
          >
            Ver Cursos
          </LoaderButton>
        </div>

        <div className="col-12 text-justify mt-5">
          <h4>Recursos </h4>
        </div>

        <div className="col-12 col-md-6 pt-3 mt-md-1">
          <p className="mb-3">Examenes por carrera</p>
          {imagesMap?.cuadroExamanes && (
            <Image src={imagesMap.cuadroExamanes} fluid rounded></Image>
          )}
        </div>

        <div className="col-12 col-md-6 pt-3 mt-md-1">
          <p className="mb-3">Infografía</p>
          {imagesMap?.guiaImage && (
            <Image src={imagesMap.guiaImage} fluid rounded></Image>
          )}
        </div>

        <div className="col-12 col-md-6 mt-3 d-flex  justify-content-center align-items-center mb-5">
          <p className="text-justify mt-3">
            Esperamos que esta guía te haya sido de ayuda para informarte sobre
            el proceso de ingreso a la USAC. Te deseamos éxitos en tu carrera. ¡
            A Estudiar !
          </p>
        </div>
      </div>
    </div>
  );
};
