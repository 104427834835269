import { Storage } from "aws-amplify";

export async function s3PublicImageUpload(file) {
  const filename = `images/${Date.now()}-${file.name
    .replace(/[^a-z0-9.]/gi, "_")
    .toLowerCase()}`;

  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
  });

  return stored.key;
}

export async function s3PublicFilesUpload(file) {
  const filename = `files_upload/${Date.now()}-${file.name
    .replace(/[^a-z0-9.]/gi, "_")
    .toLowerCase()}`;
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
  });
  return stored.key;
}

export async function s3ProtectedFileUpload(file) {
  // const filename = `files/${Date.now()}-${file.name
  //   .replace(/[^a-z0-9.]/gi, "_")
  //   .toLowerCase()}`;
  const filename = "files/" + file.name;

  const stored = await Storage.put(filename, file, {
    level: "protected",
    contentType: file.type,
  });

  return stored.key;
}

export async function s3PrivateVoucherUpload(file) {
  const filename = `vouchers/${Date.now()}-${file.name
    .replace(/[^a-z0-9.]/gi, "_")
    .toLowerCase()}`;
  const stored = await Storage.put(filename, file, {
    level: "private",
    contentType: file.type,
  });
  return stored.key;
}

export async function s3PrivateTransferVouchersUpload(file) {
  const filename = `transfer_vouchers/${Date.now()}-${file.name
    .replace(/[^a-z0-9.]/gi, "_")
    .toLowerCase()}`;
  const stored = await Storage.put(filename, file, {
    level: "private",
    contentType: file.type,
  });
  return stored.key;
}

export async function s3GetPrivateCurrentUserFile(fileKey) {
  return await Storage.get(fileKey, { level: "private" });
}

export async function s3GetPrivateFile(fileKey, userId) {
  return await Storage.get(fileKey, { level: "private", identityId: userId });
}

export async function s3GetProtectedFile(fileKey, userId) {
  return await Storage.get(fileKey, { level: "protected", identityId: userId });
}

export async function s3GetPublicCurrentUserFile(fileKey) {
  return await Storage.get(fileKey, { level: "public" });
}

export async function s3GetProtectedCurrentUserFile(fileKey) {
  return await Storage.get(fileKey, { level: "protected" });
}

export async function s3GetPublicFile(fileKey, userId) {
  return await Storage.get(fileKey, {
    level: "public",
  });
}

export async function s3PublicBucketUpload(file, folder) {
  const filename = `${folder}${Date.now()}-${file.name
    .replace(/[^a-z0-9.]/gi, "_")
    .toLowerCase()}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type,
    bucket: "virtual-tech-public-uploads",
  });

  return stored.key;
}
