import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { ROLES } from "../Constants";

export default function AuthenticatedRoute({
  children,
  role = ROLES.STUDENT,
  ...rest
}) {
  const { pathname, search } = useLocation();
  const { isAuthenticated, userInfo } = useAppContext();

  return (
    <Route {...rest}>
      {isAuthenticated && userInfo.role === role ? (
        children
      ) : isAuthenticated && userInfo.role !== role ? (
        <Redirect to={`/`} />
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  );
}

//
