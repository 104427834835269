export function create_topics_map(
  array_of_topics,
  course,
  setTopicsMapById,
  setTopicsMapByName,
  set_topics_array
) {
  let mapById = {};
  let mapByName = {};

  for (let i = 0; i < array_of_topics.length; i++) {
    const element = array_of_topics[i];
    mapById[element.topic_id] = element;
    mapByName[element.topic_name] = element;
  }
  // topicsMapById = mapById;
  // topicsMapByName = mapByName;

  let topics_ordered = [];

  for (let i = 0; i < course.topics_ordered_names.length; i++) {
    const topic_id = course.topics_ordered_names[i];
    const element = mapById[topic_id];
    topics_ordered.push(element);
  }

  setTopicsMapById(mapById);
  setTopicsMapByName(mapByName);
  set_topics_array(topics_ordered);
}
