import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import "./LoaderButton.css";

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  icon = null,
  ...props
}) {
  return (
    <Button
      className={` ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && (
        <>
          <FontAwesomeIcon icon={faSyncAlt} className="fa-spin" />{" "}
        </>
      )}
       {icon && (
        <>
          <FontAwesomeIcon icon={icon}  />{" "}
        </>
      )}

      {props.children}
    </Button>
  );
}
