import React, { Suspense, lazy, useLayoutEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ConfirmCode from "./containers/ConfirmCode";
import Courses from "./containers/Courses";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ProductPage from "./containers/ProductPage";
import ShoppingBag from "./containers/ShoppingBag";
import { ROLES } from "./Constants";
import LoadingPage from "./containers/LoadingPage";
import HowItWorksView from "./containers/admin/pages/HowItWorksView";
import DeleteDataRequest from "./containers/students/DeleteDataRequest";
import PrivacyPolicy from "./containers/unauthenticated/PrivacyPolicy";
import SendEmailsAdmin from "./containers/admin/SendEmailsAdmin";
import { FirstIngresPage } from "./containers/admin/pages/FirstIngresPage";
import RoutesMap from "./RoutesMap";
import FacebookPrivacyPolicy from "./containers/unauthenticated/FacebookPrivacyPolicy";

const EditTeacherProfile = lazy(() =>
  import("./containers/teachers/EditTeacherProfile")
);
const MyCoursesList = lazy(() => import("./containers/students/MyCoursesList"));
const CourseStudentMain = lazy(() =>
  import("./containers/students/courses/CourseStudentMain")
);
const UploadPublicImages = lazy(() =>
  import("./containers/operators/pages/UploadPublicImages")
);
const ResetPassword = lazy(() => import("./containers/ResetPassword"));
const OperatorOrders = lazy(() =>
  import("./containers/operators/orders/OperatorOrders")
);
const BankPaymentProof = lazy(() =>
  import("./containers/students/BankPaymentProof")
);
const StudentOrders = lazy(() => import("./containers/students/StudentOrders"));
const QuizzesQuery = lazy(() => import("./containers/operators/QuizzesQuery"));
const CourseCreated = lazy(() => import("./containers/teachers/CourseCreated"));
const CoursesCreatedList = lazy(() =>
  import("./containers/teachers/CoursesCreatedList")
);
const TransferRequest = lazy(() =>
  import("./containers/students/TransferRequest")
);
const ListPages = lazy(() => import("./containers/admin/pages/ListPages"));
const EditPage = lazy(() => import("./containers/admin/pages/EditPage"));
const TransactionsDashboard = lazy(() =>
  import("./containers/admin/analytics/TransactionsDashboard")
);

export default function Routes({ className }) {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={className} style={{ minHeight: "94vh" }}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/peticion-eliminar-datos">
            <DeleteDataRequest />
          </Route>
          <Route exact path="/cursos">
            <Courses />
          </Route>
          <Route exact path="/guia-inscripcion">
            <HowItWorksView />
          </Route>

          <Route exact path="/guia-primer-ingreso-usac">
            <FirstIngresPage />
          </Route>

          <Route exact path="/cursos/:id">
            <ProductPage />
          </Route>
          <UnauthenticatedRoute exact path="/fb-privacy-policy">
            <FacebookPrivacyPolicy />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute exact path="/login">
            <Login />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute exact path="/password-recovery">
            <ResetPassword />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute exact path="/signup">
            <Signup />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute exact path="/confirmar-cuenta">
            <ConfirmCode />
          </UnauthenticatedRoute>
          <Route exact path="/loading">
            <LoadingPage />
          </Route>

          {/* Rutas para Estudiantes */}

          <AuthenticatedRoute
            exact
            path="/completar-inscripcion"
            role={ROLES.STUDENT}
          >
            <ShoppingBag />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/mis-ordenes" role={ROLES.STUDENT}>
            <StudentOrders />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path="/mis-ordenes/:order_id"
            role={ROLES.STUDENT}
          >
            <BankPaymentProof />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/mis-cursos" role={ROLES.STUDENT}>
            <MyCoursesList />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            path="/mis-cursos/:product_id/:topic_id?"
            role={ROLES.STUDENT}
          >
            <CourseStudentMain />
          </AuthenticatedRoute>

          <AuthenticatedRoute
            path="/solicitar-transferencia"
            role={ROLES.STUDENT}
          >
            <TransferRequest />
          </AuthenticatedRoute>

          {/* Rutas para Profesores */}
          <AuthenticatedRoute exact path="/cursos-creados" role={ROLES.TEACHER}>
            <CoursesCreatedList />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            path="/cursos-creados/:product_id/:topic_id?"
            role={ROLES.TEACHER}
          >
            <CourseCreated />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path={RoutesMap.EditTeacherProfilePath}
            role={ROLES.TEACHER}
          >
            <EditTeacherProfile />
          </AuthenticatedRoute>

          {/* Rutas Operadores */}

          <AuthenticatedRoute
            exact
            path="/quizzes-usac-consultas"
            role={ROLES.OPERATOR}
          >
            <QuizzesQuery />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/ordenes" role={ROLES.OPERATOR}>
            <OperatorOrders />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/imagenes-publicas" role={ROLES.OPERATOR}>
            <UploadPublicImages />
          </AuthenticatedRoute>

          {/* Rutas ADMIN */}
          <AuthenticatedRoute
            exact
            path="/edit-static-pages"
            role={ROLES.ADMIN}
          >
            <ListPages />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path="/edit-static-pages/:page_id"
            role={ROLES.ADMIN}
          >
            <EditPage />
          </AuthenticatedRoute>

          <AuthenticatedRoute
            exact
            path="/transactions-dashboard"
            role={ROLES.ADMIN}
          >
            <TransactionsDashboard />
          </AuthenticatedRoute>

          <AuthenticatedRoute
            exact
            path="/send-emails-admin"
            role={ROLES.ADMIN}
          >
            <SendEmailsAdmin />
          </AuthenticatedRoute>

          {/* Finally, catch all unmatched routes */}

          <UnauthenticatedRoute exact path="/privacy-policy">
            <PrivacyPolicy />
          </UnauthenticatedRoute>

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  );
}

//const   = lazy(() => import(  ));
