import React, { useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import ReactPixel from "react-facebook-pixel";

export default function HowItWorksView() {
  useEffect(() => {
    ReactPixel.pageView();
  }, []);
  return (
    <div className="container pt-5 pb-5">
      <h2>Guía de Inscripción</h2>
      <p className="text-lead" className="mt-5">
        En este video te explicamos las pasos para inscribirte a los cursos
        utilizando nuestra plataforma educativa.
      </p>
      <div className="mx-md-5 mt-5">
        <Vimeo video={"507749154"} responsive className="p-0" />
      </div>
    </div>
  );
}
