import { useContext, createContext } from "react";

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export const CourseContext = createContext(null);

export function useCourseContext() {
  return useContext(CourseContext);
}
