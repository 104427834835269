import { faEnvelope, faHeading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SelectDropDown } from "../../components/FormComponents";
import LoaderButton from "../../components/LoaderButton";
import RichTextArea from "../../components/RichTextArea";
import {
  requiredError,
  textBoxMaxLengthError,
} from "../../messages/FieldsErrors";
import { TextBox } from "../../components/FormComponents";

export default function GenericSendEmails({
  listCourses,
  sendEmailFunction,
  isSendButtonLoading,
}) {
  const { register, handleSubmit, errors, control } = useForm({});

  return (
    <div className="my-5 container">
      <h2 className="mt-5 pt-5">Enviar Correos</h2>

      <SelectDropDown
        className="mt-5"
        label="Curso"
        name="product_id"
        register={register(requiredError)}
        error={errors?.product_id}
        optionsObjArray={listCourses}
      />

      <TextBox
        label="Subject"
        name="email_subject"
        icon={faHeading}
        register={register(textBoxMaxLengthError(150))}
        error={errors?.email_subject}
        className="mt-4"
      />

      <FormGroup controlId="email_text_content" className="mt-4">
        <Form.Label>Contenido</Form.Label>
        <RichTextArea
          name="email_text_content"
          control={control}
          rules={textBoxMaxLengthError(2000)}
        />
        <p className="text-info">{errors?.email_text_content?.message}</p>
      </FormGroup>

      <LoaderButton
        className="my-4 btn-primary"
        isLoading={isSendButtonLoading}
        onClick={handleSubmit(sendEmailFunction)}
      >
        <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
        Enviar Correo
      </LoaderButton>
    </div>
  );
}
