import { Card } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import "./FacebookReviewsCarousel.css";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faChevronLeft,
  faChevronRight,
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons";
import facebookReviewsJSON from "../assets/FacebookReviews.json";
import StarRatingComponent from "react-star-rating-component";

export default function FacebookReviewsCarousel({ isLight = false }) {
  const [facebookReviews, setFacebookReviews] = useState([]);
  const [initialReviewIndex, setInitialReviewIndex] = useState(0);
  const reviewsRef = useRef();
  let offset = 3;

  useEffect(() => {
    let reviews = facebookReviewsJSON.filter(
      (review) =>
        review.recommendation_type === "positive" && review.review_text
    );
    reviews = reviews.sort(() => Math.random() - 0.5);
    setFacebookReviews(reviews);
  }, []);

  function renderStudentsReviews() {
    const calculatedOfsset = window.innerWidth >= 768 ? 3 : 1;
    offset = calculatedOfsset;
    let reviewsList = facebookReviews.slice(
      initialReviewIndex,
      initialReviewIndex + calculatedOfsset
    );
    return reviewsList.map((review, index) => {
      return (
        <div className="col-12 col-md-4 my-3" key={index}>
          <Card border="light" className="Testimonial">
            <div className="px-3 pt-2 pb-2 ">
              <div className="d-flex flex-row  justify-content-start ">
                {review?.rating > 3 ? (
                  <StarRatingComponent
                    name={`stars${index}`}
                    className="text-center"
                    editing={false}
                    starCount={5}
                    value={Math.round(review.rating)}
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      className="mb-3"
                      size="lg"
                      icon={faBookmark}
                      color="rgb(238,105,125)"
                    />
                  </>
                )}
              </div>
              <blockquote
                className={` ${isLight ? "" : "blockquote"} text-center `}
              >
                <div className="QuoteText d-flex flex-column justify-content-center">
                  <p className={`mb-0`} style={{ overflowY: "auto" }}>
                    <FontAwesomeIcon icon={faQuoteLeft} /> {review.review_text}
                  </p>
                </div>
                <div className="row d-flex flex-row justify-content-between px-3 pt-2">
                  {review?.reviewer?.id && (
                    <Image
                      src={`../images/reviews_images/${review.reviewer.id}.jpg`}
                      className="p-0 m-0 mr-1"
                      style={{ width: "26px", height: "26px" }}
                      roundedCircle
                    />
                  )}
                  {review?.reviewer && (
                    <footer className="blockquote-footer">
                      <strong>{review.reviewer.name}</strong>{" "}
                    </footer>
                  )}
                </div>
              </blockquote>
            </div>
          </Card>
        </div>
      );
    });
  }
  return (
    <div className="FacebookReviewsCarousel">
      {isLight ? (
        <h4 className="mb-3">Reseñas de Alumnos</h4>
      ) : (
        <h2 className="mb-5">Reseñas de Alumnos</h2>
      )}
      <div className="row pt-4 d-flex flex-row  px-0 mx-0">
        <div
          className="col-1 d-flex flex-column justify-content-center px-0 align-items-start"
          onClick={() => {
            const nextIndex = initialReviewIndex - offset;
            if (nextIndex >= 0) {
              setInitialReviewIndex(nextIndex);
            } else {
              setInitialReviewIndex(facebookReviews.length - offset);
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div
          className="col-10 px-0 flex-column justify-content-center "
          ref={reviewsRef}
        >
          <div
            className="row flex-row flex-nowrap  justify-content-center "
            style={{ overflowX: "auto", overflowY: "hidden" }}
          >
            {renderStudentsReviews()}
          </div>
        </div>
        <div
          className="col-1 d-flex flex-column justify-content-center align-items-end px-0"
          onClick={() => {
            const nextIndex = initialReviewIndex + offset;
            if (nextIndex <= facebookReviews.length - offset) {
              setInitialReviewIndex(nextIndex);
            } else {
              setInitialReviewIndex(0);
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
}
