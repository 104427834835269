import config from "../config";
import mime from "mime-types";

export function formatDate(dateObject) {
  try {
    const year = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
      dateObject
    );
    const month = new Intl.DateTimeFormat("es", { month: "long" }).format(
      dateObject
    );
    const day = dateObject.getDate();

    return `${day} ${month} ${year}`;
  } catch (error) {
    return null;
  }
}

export function formatDateTimeWithoutTimeZone(dateObject) {
  return dateObject
    .toISOString()
    .replace("Z", " ")
    .replace("T", " ")
    .split(".")[0];
}

export function formatDateWithHours(dateObject) {
  try {
    const year = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
      dateObject
    );
    const month = new Intl.DateTimeFormat("es", { month: "long" }).format(
      dateObject
    );
    const day = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  } catch (error) {
    console.log("error parsing date", error);
    return null;
  }
}

export function isExpiredDate(isoDate1, isoDate2) {
  const date1 = new Date(isoDate1);
  const date2 = new Date(isoDate2);
  return date2 > date1;
}

export function isTodayExpiredDate(ISOStringDate) {
  try {
    const todayDate = new Date();
    const date2 = new Date(ISOStringDate);
    return todayDate > date2;
  } catch (error) {
    return null;
  }
}

export function getFileExtension(filename) {
  let re = /(?:\.([^.]+))?$/;
  return re.exec(filename)[1];
}

export function getFileMimeType(filename) {
  return mime.lookup(filename);
}

export function isImageCorrectSize(image) {
  if (image && image.size > config.MAX_ATTACHMENT_SIZE) {
    return false;
  } else {
    return true;
  }
}

export function getMaxSizeErrorMessage() {
  return `Por favor sube una imagen menor a ${
    config.MAX_ATTACHMENT_SIZE / 1000000
  } MB`;
}

export function roundTwoDecimals(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
