import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import React from "react";
import { FormGroup, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

export function CountrySelectMenu({
  setSelectedCountry,
  control,
  name,
  rules,
  label = null,
  error = null,
  text_muted = null,
  defaultValue = "",
  position_text_muted = "top",
  containerClassName = "",
  ...rest
}) {
  return (
    <FormGroup className={containerClassName}>
      {label && <Form.Label>{label}</Form.Label>}

      {text_muted && position_text_muted === "top" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}

      <div className="mt-1">
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, value }) => (
            <CountryDropdown
              value={value}
              onChange={(val) => {
                onChange(val);
                setSelectedCountry(val);
              }}
              {...rest}
              style={{ width: "100%" }}
            />
          )}
        />
      </div>

      {error && <p className="text-info">{error?.message}</p>}
      {text_muted && position_text_muted === "down" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
    </FormGroup>
  );
}

export function RegionSelectMenu({
  control,
  name,
  rules,
  label = null,
  error = null,
  text_muted = null,
  position_text_muted = "top",
  containerClassName = "",
  ...rest
}) {
  return (
    <FormGroup className={containerClassName}>
      {label && <Form.Label>{label}</Form.Label>}

      {text_muted && position_text_muted === "top" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}

      <div className="mt-1">
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={""}
          render={({ onChange, value }) => (
            <RegionDropdown
              {...rest}
              value={value}
              onChange={(val) => onChange(val)}
              style={{ width: "100%" }}
            />
          )}
        />
      </div>

      {error && <p className="text-info">{error?.message}</p>}
      {text_muted && position_text_muted === "down" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
    </FormGroup>
  );
}
