import ReactPixel from "react-facebook-pixel";

export function simplePixelInit() {
  try {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("714269268712434", {}, options);
  } catch (error) {
    console.log("error on Facebook Pixel");
  }
}

export function advancedMatchingPixelInit(user) {
  try {
    const advancedMatching = {
      em: user.email,
      fn: user.name,
      ln: user.lastname,
    };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("714269268712434", advancedMatching, options);
  } catch (error) {
    console.log("error on Facebook Pixel");
  }
}

export function fbPixelAddToCart(content_type, products_keys_list) {
  try {
    let content_ids = products_keys_list.map(
      (element) => element.split("&")[0]
    );
    console.log("content_ids", content_ids);
    ReactPixel.track("AddToCart", { content_type, content_ids });
  } catch (error) {
    console.log("error on add to cart fb pixel");
  }
}

export function fbPixelViewContent(content_type, content_ids) {
  try {
    ReactPixel.track("ViewContent", { content_type, content_ids });
  } catch (error) {
    console.log("error on add to cart fb pixel");
  }
}

export function fbPixelInitiateCheckout(content_ids) {
  try {
    console.log("content_ids", content_ids);
    ReactPixel.track("InitiateCheckout", {
      content_type: "product",
      content_ids,
    });
  } catch (error) {
    console.log("error on add to cart fb pixel");
  }
}
