import React from "react";

export default function DeleteDataRequest() {
  return (
    <div className="container py-5 my-5">
      <h2 className="my-5">Solicitud de eliminación de datos</h2>
      <p className="my-5">
        Si deseas que eliminemos tus datos, por favor, envía un correo a
        virtualtechgt@gmail.com y con gusto te ayudaremos a eliminar tus datos y
        mantener tu privacidad.
      </p>
    </div>
  );
}
