import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import logo from "./logo.png";
import {
  Navbar,
  Nav,
  NavLink,
  Button,
  NavDropdown,
  NavItem,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import { API, Auth, Hub } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faUserAstronaut,
  faMoon,
  faSun,
  faChalkboardTeacher,
  faUserTie,
  faUserCog,
  faShoppingBag,
  faScroll,
  faBook,
  faBookOpen,
  faBookReader,
  faImage,
  faMoneyBill,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "./libs/contextLib";
import { ROLES } from "./Constants";
import { Image } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ShoppingSideBar from "./containers/ShoppingSideBar";
import { useOutsideAlerter, usePageTracking } from "./libs/hooksLib";
import Footer from "./Footer";
import {
  advancedMatchingPixelInit,
  simplePixelInit,
} from "./libs/facebookPixelLib";
import RoutesMap from "./RoutesMap";

function App(props) {
  const materia =
    "https://stackpath.bootstrapcdn.com/bootswatch/4.5.2/materia/bootstrap.min.css";
  const dark =
    "https://stackpath.bootstrapcdn.com/bootswatch/4.5.2/cyborg/bootstrap.min.css";
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userInfo, changeUserInfo] = useState({
    name: "",
    lastname: "",
    email: "",
    role: "",
  });
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isDark, setIsDark] = useState(false);
  const [showShoppingBag, setShowShoppingBag] = useState(false);
  const shoppingBagRef = useRef(null);
  useOutsideAlerter(shoppingBagRef, clickOutsideShoppingBag);
  const [userEnrolledCourses, setUserEnrolledCourses] = useState({});
  const [showFooter, setShowFooter] = useState(true);
  usePageTracking();

  function clickOutsideShoppingBag() {
    setShowShoppingBag(false);
  }

  function setUserInfo(cognitoUserSession) {
    const attr = cognitoUserSession.getIdToken().decodePayload();
    console.log("attr", attr);
    const roles = attr["cognito:groups"];
    let role = null;
    if (
      roles?.[0] === ROLES.TEACHER ||
      roles?.[0] === ROLES.OPERATOR ||
      roles?.[0] === ROLES.ADMIN
    ) {
      role = roles[0];
    } else {
      role = ROLES.STUDENT;
    }

    let provider = attr?.identities?.[0]?.providerName;
    let picture_url = null;
    if (provider === "Facebook") {
      picture_url = attr?.picture ? JSON.parse(attr?.picture)?.data?.url : null;
    } else if (provider === "Google") {
      picture_url = attr?.picture;
    }

    const user = {
      name: attr.name,
      lastname: attr.family_name,
      email: attr.email,
      picture_url,
      provider: provider ? provider : "Email",
      role,
    };
    changeUserInfo(user);

    if (user?.role === ROLES.STUDENT) {
      callListCoursesHistoyAPI();
    }
    advancedMatchingPixelInit(user);
    console.log("user", user);
  }

  async function callListCoursesHistoyAPI() {
    try {
      let coursesResult = await API.get(
        "virtualtech",
        "/student-course-history"
      );
      let map = {};
      for (let i = 0; i < coursesResult.length; i++) {
        const course = coursesResult[i];
        map[course.product_id] = true;
      }

      setUserEnrolledCourses(map);
    } catch (error) {
      console.log("error in call list courses history API in App", error);
    }
  }

  function getUserRoleIcon() {
    switch (userInfo.role) {
      case ROLES.STUDENT:
        return <FontAwesomeIcon icon={faUserAstronaut} />;
      case ROLES.ADMIN:
        return <FontAwesomeIcon icon={faUserCog} />;
      case ROLES.OPERATOR:
        return <FontAwesomeIcon icon={faUserTie} />;
      case ROLES.TEACHER:
        return <FontAwesomeIcon icon={faChalkboardTeacher} />;
      default:
        return <></>;
    }
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    changeUserInfo(null);
  }

  function handleSwitch() {
    document.getElementById("mycss").remove();
    let head = document.head;
    let link = document.createElement("link");
    let fonts = document.getElementById("myFonts");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = !isDark ? dark : materia;
    link.id = "mycss";
    head.appendChild(link);
    head.insertBefore(link, fonts);
    localStorage.setItem("isDark", !isDark);
    setIsDark(!isDark);
  }

  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          await verifyAuthenticatedUser();
          const previousRedirectURL = localStorage.getItem(
            "previousRedirectURL"
          );
          if (previousRedirectURL !== null && previousRedirectURL !== "null") {
            localStorage.setItem("previousRedirectURL", "");
            props.history.push(previousRedirectURL);
          } else {
            props.history.push("/");
          }
          break;
        case "signOut":
          console.log("SignOut");
          break;
        default:
          break;
      }
    });

    async function onLoad() {
      let darkStorage = localStorage.getItem("isDark") === "true";
      if (darkStorage) {
        handleSwitch();
      }
      verifyAuthenticatedUser();
    }
    onLoad();
  }, []);

  async function verifyAuthenticatedUser() {
    try {
      let cognitoUserSession = await Auth.currentSession();
      console.log(
        "currentAuthenticatedUser",
        await Auth.currentAuthenticatedUser()
      );
      console.log("cognitoUserSession", cognitoUserSession);
      setUserInfo(cognitoUserSession);
      userHasAuthenticated(true);
    } catch (e) {
      // No user
      simplePixelInit();
      if (e !== "No current user") {
        console.log("Error en current session");
        console.log(e);
      }
    }
    setIsAuthenticating(false);
  }

  function renderProfileMenuIcon() {
    return (
      <>
        {userInfo.picture_url && (
          <Image
            src={userInfo.picture_url}
            className="p-0 m-0 mr-1"
            style={{ width: "26px", height: "26px" }}
            roundedCircle
          />
        )}
        {userInfo.name + " "}
        {userInfo.role !== ROLES.STUDENT && getUserRoleIcon()}
      </>
    );
  }

  return (
    !isAuthenticating && (
      <div
        className={`App container-fluid h-100 ${
          isDark ? `dark` : "light"
        } mt-5`}
      >
        <div>
          <Navbar
            collapseOnSelect
            bg="warning"
            variant="dark"
            fixed="top"
            expand="lg"
            className={`${isDark ? `p-md-1` : "p-md-0"} p-1 `}
          >
            <Navbar.Brand>
              <LinkContainer to="/" style={{ color: "#FFF" }}>
                <NavLink style={{ color: "#FFF" }}>
                  {" "}
                  <img
                    alt=""
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  />{" "}
                  Virtual Tech Tutorías
                </NavLink>
              </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <LinkContainer to="/cursos">
                  <NavLink>Cursos</NavLink>
                </LinkContainer>
                <LinkContainer to="/guia-inscripcion">
                  <NavLink>Guía Inscripción</NavLink>
                </LinkContainer>

                <LinkContainer to="/guia-primer-ingreso-usac">
                  <NavLink>Ingreso USAC</NavLink>
                </LinkContainer>

                {isAuthenticated && userInfo.role === ROLES.OPERATOR ? (
                  // OPERATORS
                  <>
                    <LinkContainer to="/quizzes-usac-consultas">
                      <NavLink>
                        {" "}
                        <FontAwesomeIcon icon={faEnvelope} /> Emails
                      </NavLink>
                    </LinkContainer>
                    <LinkContainer to="/ordenes/pendientes-revision">
                      <NavLink>
                        <FontAwesomeIcon icon={faScroll} /> Ordenes
                      </NavLink>
                    </LinkContainer>
                    <LinkContainer to="/imagenes-publicas">
                      <NavLink>
                        <FontAwesomeIcon icon={faImage} /> Imágenes
                      </NavLink>
                    </LinkContainer>
                  </>
                ) : (
                  <></>
                )}
              </Nav>
              <Nav className="ml-auto">
                {isAuthenticated ? (
                  <>
                    {userInfo.role === ROLES.STUDENT && (
                      <>
                        <LinkContainer to="/mis-ordenes">
                          <NavLink>
                            <FontAwesomeIcon icon={faScroll} /> Ordenes
                          </NavLink>
                        </LinkContainer>
                        <LinkContainer to="#">
                          <NavLink
                            onClick={() => {
                              setShowShoppingBag(!showShoppingBag);
                            }}
                          >
                            <FontAwesomeIcon icon={faShoppingBag} /> Canasta
                          </NavLink>
                        </LinkContainer>
                        <LinkContainer to="/mis-cursos">
                          <NavLink>
                            <FontAwesomeIcon icon={faBookOpen} /> Mis Cursos
                          </NavLink>
                        </LinkContainer>
                      </>
                    )}

                    {userInfo.role === ROLES.TEACHER && (
                      // TEACHERS
                      <LinkContainer to="/cursos-creados">
                        <NavLink>
                          <FontAwesomeIcon icon={faBookReader} /> Cursos Creados
                        </NavLink>
                      </LinkContainer>
                    )}

                    {userInfo.role === ROLES.ADMIN && (
                      // ADMIN
                      <>
                        <LinkContainer to="/send-emails-admin">
                          <NavLink>
                            <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                            Emails
                          </NavLink>
                        </LinkContainer>
                        <LinkContainer to="/transactions-dashboard">
                          <NavLink>
                            <FontAwesomeIcon icon={faMoneyBill} /> Transacciones
                          </NavLink>
                        </LinkContainer>
                      </>
                    )}
                    {userInfo.role === ROLES.TEACHER ? (
                      <NavDropdown
                        title={renderProfileMenuIcon()}
                        disabled={userInfo.role !== ROLES.TEACHER}
                      >
                        <NavDropdown.Item
                          eventKey="4.1"
                          onClick={() =>
                            props.history.push(RoutesMap.EditTeacherProfilePath)
                          }
                        >
                          Editar Perfil
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      <NavLink>{renderProfileMenuIcon()}</NavLink>
                    )}

                    <NavLink onClick={handleLogout}>Salir</NavLink>
                  </>
                ) : (
                  <>
                    <LinkContainer className="" to="/login">
                      <NavLink className="p-0 m-0 ">
                        <Button
                          variant="warning"
                          size="lg"
                          block
                          className="buttonLogin text-capitalize py-2 my-2 my-md-0"
                        >
                          <FontAwesomeIcon icon={faUserGraduate} /> Ingresar
                        </Button>
                      </NavLink>
                    </LinkContainer>
                  </>
                )}
                <NavLink onClick={handleSwitch}>
                  <FontAwesomeIcon icon={isDark ? faSun : faMoon} />{" "}
                  {isDark ? "Light" : "Dark"}
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={4500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppContext.Provider
          value={{
            setShowFooter,
            isAuthenticated,
            userHasAuthenticated,
            userInfo,
            setUserInfo,
            isDark,
            setShowShoppingBag,
            userEnrolledCourses,
            setUserEnrolledCourses,
            isStudent: userInfo?.role === ROLES.STUDENT,
          }}
        >
          {isAuthenticated && showShoppingBag && (
            <div className="ShoppingBar" ref={shoppingBagRef}>
              <ShoppingSideBar />
            </div>
          )}

          <Routes className={`Routes ${isDark ? "dark" : ""} `} />
        </AppContext.Provider>
        <Footer showFooter={showFooter} isDark={isDark} />
      </div>
    )
  );
}

export default withRouter(App);
