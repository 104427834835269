import React from "react";
import {
  FormGroup,
  FormControl,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FormComponents.css";
import LoaderButton from "./LoaderButton";

export function InfoItemPair({
  label1,
  value1,
  label2,
  value2,
  boldLabel = false,
}) {
  return (
    <div className="row">
      <div className="col-md-6 mb-2">
        {value1 && (
          <div className="row ">
            <div className={`col-4  ${boldLabel ? "font-weight-bold" : ""} `}>
              {label1}
            </div>
            <div className="col-8">{value1}</div>
          </div>
        )}
      </div>
      <div className="col-md-6  mb-2">
        {value2 && (
          <div className="row">
            <div className={`col-4  ${boldLabel ? "font-weight-bold" : ""} `}>
              {label2}
            </div>
            <div className="col-8">{value2}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export function ItemInfo({ label, text, className, icon }) {
  return (
    <>
      {text ? (
        <div className={className}>
          <p
            className="text-center text-muted  font-weight-light "
            style={{ fontSize: "85%" }}
          >
            {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
            {label && label + " "}
            {text}
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export function ConfirmationModal({
  showConfirmationModal,
  setShowConfirmationModal,
  questionText,
  itemToDeleteName,
  onConfirm,
}) {
  return (
    <Modal
      show={showConfirmationModal}
      centered
      onHide={() => setShowConfirmationModal(false)}
    >
      <Modal.Header closeButton>
        <strong>Confirmación</strong>
      </Modal.Header>
      <Modal.Body>
        <p>
          {"¿ " + questionText + " "} <strong>{itemToDeleteName}</strong> ?{" "}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="m-0 p-0">
          <LoaderButton
            className="mr-3 btn-secondary"
            onClick={() => onConfirm()}
          >
            Confirmar
          </LoaderButton>
          <LoaderButton onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </LoaderButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function SelectDropDown({
  register,
  name,
  icon = null,
  label = null,
  className = "",
  error = null,
  text_muted = null,
  readOnly = false,
  optionsObjArray,
  position_text_muted = "top",
  emptyOption = true,
  placeholder = "",
  selectedValue = null,
}) {
  function renderOptions() {
    return optionsObjArray.map((option, index) => (
      <option
        key={index}
        value={option.value != null ? option.value : option}
        // selected={option.value === selectedValue ? "selected" : ""}
      >
        {option.label != null ? option.label : option}
      </option>
    ));
  }

  return (
    <Form.Group controlId={name} className={className}>
      {label && <Form.Label>{label}</Form.Label>}
      {text_muted && position_text_muted === "top" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
      <InputGroup>
        {icon && (
          <InputGroup.Prepend>
            <InputGroup.Text className="border-right-0 bg-transparent ">
              <FontAwesomeIcon icon={icon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          as="select"
          name={name}
          ref={register}
          readOnly={readOnly}
          defaultValue={selectedValue}
        >
          {emptyOption && (
            <option
              key={"empty"}
              className="text-secondary text-muted"
              value=""
              children={placeholder}
            ></option>
          )}
          {renderOptions()}
        </Form.Control>
      </InputGroup>

      {error && <p className="text-info">{error?.message}</p>}
      {text_muted && position_text_muted === "down" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
    </Form.Group>
  );
}

export function TextBox({
  register,
  name,
  hidden = false,
  label = null,
  icon = null,
  placeholder = "",
  type = "",
  className = "",
  defaultValue = "",
  formControlClassName = "",
  error = null,
  text_muted = null,
  position_text_muted = "top",
  readOnly = false,
  formControlProps = {},
}) {
  return (
    <FormGroup controlId={name} className={className} hidden={hidden}>
      {label && <Form.Label>{label}</Form.Label>}

      {text_muted && position_text_muted === "top" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
      <InputGroup>
        {icon && (
          <InputGroup.Prepend>
            <InputGroup.Text className="border-right-0 bg-transparent ">
              <FontAwesomeIcon icon={icon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}

        <FormControl
          name={name}
          placeholder={placeholder}
          className={`${icon ? "border-left-0" : ""} ${formControlClassName}`}
          size="lg"
          type={type}
          ref={register}
          readOnly={readOnly}
          defaultValue={defaultValue}
          {...formControlProps}
        />
      </InputGroup>
      {/* {label && <label class="form-label">{label}</label>} */}

      {error && <p className="text-info">{error?.message}</p>}
      {text_muted && position_text_muted === "down" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
    </FormGroup>
  );
}

export function ControlledTextBox({
  label = null,
  icon = null,
  placeholder = "",
  type = "",
  className = "",
  formControlClassName = "",
  error = null,
  text_muted = null,
  position_text_muted = "top",
  readOnly = false,
  formControlProps = {},
}) {
  return (
    <FormGroup className={className}>
      {label && <Form.Label>{label}</Form.Label>}

      {text_muted && position_text_muted === "top" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
      <InputGroup>
        {icon && (
          <InputGroup.Prepend>
            <InputGroup.Text className="border-right-0 bg-transparent ">
              <FontAwesomeIcon icon={icon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}

        <FormControl
          placeholder={placeholder}
          className={`${icon ? "border-left-0" : ""} ${formControlClassName}`}
          size="lg"
          type={type}
          readOnly={readOnly}
          {...formControlProps}
        />
      </InputGroup>
      {/* {label && <label class="form-label">{label}</label>} */}

      {error && <p className="text-info">{error?.message}</p>}
      {text_muted && position_text_muted === "down" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
    </FormGroup>
  );
}
