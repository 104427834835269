import React, { useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import image from "../assets/Basicos2.jpg";
import "./Login.css";
import LoginForm from "./unauthenticated/LoginForm";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container py-md-5 py-4">
      <Card>
        <Card.Body className="p-0">
          <div className="row">
            <div className="col-lg-8 col-md-12 order-lg-1 order-2 pr-lg-0 ">
              <Image className="" src={image} fluid />
            </div>
            <div className="col-lg-4 col-md-12 order-lg-2 order-1 pl-lg-0 ">
              <div className="mx-2 mx-md-3 h-100">
                <LoginForm />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
