import React, { useState, useEffect } from "react";
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Card,
  Image,
  NavLink,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { API, Auth } from "aws-amplify";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  createButton,
} from "react-social-login-buttons";
import "./LoginForm.css";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../libs/hooksLib";
import { useAppContext } from "../../libs/contextLib";

function queryString(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function LoginForm({ isInModal = false, setShowLoginModal }) {
  const {
    userHasAuthenticated,
    setUserInfo,
    setShowShoppingBag,
    userInfo,
  } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginFields, setShowLoginFields] = useState(!isInModal);

  const config = {
    text: "Email",
    icon: "envelope",
    iconFormat: (name) => `fas fa-${name}`,
    style: { background: "rgb(41,128,185)" },
    activeStyle: { background: "rgb(21, 104, 158)" },
  };
  const EmailButton = createButton(config);

  useEffect(() => {
    // const previousRedirectURL = localStorage.getItem("previousRedirectURL");
    // if (previousRedirectURL === null || previousRedirectURL === "null") {
    const redirect = queryString("redirect");
    if (redirect) {
      localStorage.setItem("previousRedirectURL", redirect);
    }
    // }
  }, []);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.signIn(fields.email.trim(), fields.password);
      let cognitoUserSession = await Auth.currentSession();
      setUserInfo(cognitoUserSession);
      userHasAuthenticated(true);
      const pending_products_keys_list = JSON.parse(
        localStorage.getItem("pending_products_keys_list")
      );
      if (
        pending_products_keys_list &&
        pending_products_keys_list?.length > 0
      ) {
        addToShoppingBagAPI(pending_products_keys_list);
        localStorage.setItem("pending_products_keys_list", null);
      }
      if (isInModal) {
        setShowLoginModal(false);
      }
    } catch (e) {
      handleFieldChange({ target: { id: "password", value: "" } });
      alert(e.message);
      setIsLoading(false);
    }
  }

  async function addToShoppingBagAPI(products_keys_list) {
    try {
      await API.post("virtualtech", `/shopping-bag`, {
        body: {
          products_keys_list: products_keys_list,
          userData: userInfo,
        },
      });
      setShowShoppingBag(true);
    } catch (error) {
      console.log("Couln't add to shoppingBag");
    }
  }

  return (
    <div className="LoginForm row d-flex flex-row justify-content-center align-items-center h-100">
      <div className="col-12 h-45">
        <div className="d-flex flex-column justify-content-center align-items-center py-3">
          {showLoginFields ? (
            <h5
              className="my-2 align-self-center"
              style={{ fontSize: "1.1rem" }}
            >
              Ingreso Recomendado{" "}
            </h5>
          ) : (
            <h5
              className="my-2 align-self-center"
              style={{ fontSize: "1.1rem" }}
            >
              Ingresar
            </h5>
          )}

          <FacebookLoginButton
            align="center"
            className="Facebook mt-4"
            style={{ backgroundColor: "rgb(66,133,244)" }}
            activeStyle={{ backgroundColor: "#4267B2" }}
            onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
          >
            <span>Facebook</span>
          </FacebookLoginButton>
          <GoogleLoginButton
            align="center"
            className="Google mt-2"
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          >
            <span>Google</span>
          </GoogleLoginButton>
          {!showLoginFields && (
            <EmailButton
              className="mt-2"
              align="center"
              onClick={() => setShowLoginFields(true)}
            >
              <span>Email</span>
            </EmailButton>
          )}
        </div>
      </div>

      {showLoginFields && (
        <div className="col-12 h-55">
          <div className="container px-2 pb-3">
            <hr data-content="ó" className="hr-text px-5"></hr>
          </div>
          <div className="container">
            <Form onSubmit={handleSubmit} className="mb-3">
              <FormGroup controlId="email">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="border-right-0 bg-transparent ">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder="Correo"
                    className="border-left-0"
                    size="lg"
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup controlId="password">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="border-right-0 bg-transparent">
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder="Contraseña"
                    className="border-left-0"
                    size="lg"
                    type="password"
                    value={fields.password}
                    onChange={handleFieldChange}
                  />
                </InputGroup>
              </FormGroup>

              <LoaderButton
                block
                type="submit"
                size="large"
                isLoading={isLoading}
                disabled={!validateForm()}
                className="mt-4"
              >
                Login
              </LoaderButton>
            </Form>
            <div className="row">
              <LinkContainer
                to="/password-recovery"
                className="col-md-8  col-sm-12 text-secondary"
              >
                <NavLink className="text-md-left text-center font-weight-light">
                  <small>Recuperar Contraseña</small>
                </NavLink>
              </LinkContainer>
              <LinkContainer
                to="/signup"
                className="col-md-4 col-sm-12 text-secondary"
              >
                <NavLink className="text-md-right text-center font-weight-light">
                  <small>Regístrate</small>
                </NavLink>
              </LinkContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
