import React, { useEffect, useState } from "react";
import GenericSendEmails from "./GenericSendEmails";
import { API } from "aws-amplify";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

export default function SendEmailsAdmin() {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSendButtonLoading, setisSendButtonLoading] = useState(false);

  async function getAllCourses() {
    setIsLoading(true);
    try {
      const responseCourses = await API.get("virtualtech", "/products");
      const courses = responseCourses.map((item) => {
        return { label: item.name, value: item.product_id };
      });
      setCourses(courses);
      setIsLoading(false);
    } catch (error) {
      console.log("Error en Product Courses List");
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllCourses();
  }, []);

  async function sendEmailFunction(data) {
    setisSendButtonLoading(true);
    try {
      const response = await API.post(
        "virtualtech",
        `/send-email-to-students/${data.product_id}`,
        {
          body: data,
        }
      );
      console.log("data", data);
      console.log("sendEmailResponse", response);
      setIsLoading(true);
      toast.success("Emails enviados correctamente.");
      setIsLoading(false);
    } catch (error) {
      const message = "Error enviando email.";
      toast.error(message);
      console.log(message, error);
    } finally {
      setisSendButtonLoading(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="container py-5 px-5 d-flex flex-row justify-content-center align-items-center">
          {" "}
          <Spinner animation="grow" variant="primary" />{" "}
        </div>
      ) : (
        <GenericSendEmails
          sendEmailFunction={sendEmailFunction}
          listCourses={courses}
          isSendButtonLoading={isSendButtonLoading}
        />
      )}
    </>
  );
}
