import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  faEnvelope,
  faLock,
  faSortNumericDown,
} from "@fortawesome/free-solid-svg-icons";
import { API, Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextBox } from "../components/FormComponents";
import { textBoxMaxLengthError } from "../messages/FieldsErrors";

export default function ConfirmCode() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    userHasAuthenticated,
    setUserInfo,
    setShowShoppingBag,
    userInfo,
  } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      email: location.state?.email,
      password: location.state?.password,
    },
  });
  const password = location?.state?.password;

  async function handleConfirmationSubmit(data) {
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(data.email.trim(), data.confirmationCode.trim());
      if (password) {
        await Auth.signIn(data.email.trim(), password);
        let cognitoUserSession = await Auth.currentSession();
        setUserInfo(cognitoUserSession);
        userHasAuthenticated(true);
        const pending_products_keys_list = JSON.parse(
          localStorage.getItem("pending_products_keys_list")
        );
        if (
          pending_products_keys_list &&
          pending_products_keys_list?.length > 0
        ) {
          addToShoppingBagAPI(pending_products_keys_list);
          localStorage.setItem("pending_products_keys_list", null);
        }
      } else {
        history.push("/login");
      }
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  async function addToShoppingBagAPI(products_keys_list) {
    try {
      await API.post("virtualtech", `/shopping-bag`, {
        body: {
          products_keys_list: products_keys_list,
          userData: userInfo,
        },
      });
      setShowShoppingBag(true);
    } catch (error) {
      console.log("Couln't add to shoppingBag");
    }
  }

  return (
    <div className="container">
      <div className="container">
        <div className="px-md-5 d-flex flex-column justify-content-center py-3 ">
          <h2 className="my-2 align-self-center">Confirmar Cuenta</h2>
          <p className="my-3 align-self-center mb-5">
            Revisa el correo electrónico que ingresaste y busca el código de
            confirmación de cuenta. Puede que también debas revisar en spam o
            correo no deseado. Ingresa el código a continuación.
          </p>
          <Form
            onSubmit={handleSubmit(handleConfirmationSubmit)}
            className="mb-4"
          >
            <TextBox
              name="email"
              placeholder="Correo"
              icon={faEnvelope}
              register={register(textBoxMaxLengthError(50))}
              error={errors?.email}
              className="mb-4"
            />
            <TextBox
              name="confirmationCode"
              placeholder="Código"
              icon={faSortNumericDown}
              register={register(textBoxMaxLengthError(50))}
              error={errors?.confirmationCode}
            />

            <LoaderButton
              block
              type="submit"
              size="large"
              isLoading={isLoading}
              className="mt-5"
            >
              Confirmar
            </LoaderButton>
          </Form>
        </div>
      </div>
    </div>
  );
}
