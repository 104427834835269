export default {
  MAX_ATTACHMENT_SIZE: 30000000,
  s3: {
    REGION: "us-east-2",
    BUCKET: "virtual-tech-bucket",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://fkt6z99wa1.execute-api.us-east-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_yhEjZwLyh",
    APP_CLIENT_ID: "6ek56b6j3k53hdqjlstdo4qt1s",
    IDENTITY_POOL_ID: "us-east-2:f567e87c-3016-4154-96a2-3e554833f3a5",
  },
  social: {
    FB: "1316776411756305",
  },
};
