import React, { useState } from "react";
import { Form, NavLink } from "react-bootstrap";
import {
  faEnvelope,
  faLock,
  faUser,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import LoaderButton from "../components/LoaderButton";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextBox } from "../components/FormComponents";
import { textBoxMaxLengthError } from "../messages/FieldsErrors";
import "./Signup.css";

export default function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm();

  async function handleSubmitForm(data) {
    setIsLoading(true);
    try {
      await Auth.signUp({
        username: data.email.trim(),
        password: data.password,
        attributes: {
          name: data.name.trim(),
          family_name: data.lastname.trim(),
        },
      });

      history.push("/confirmar-cuenta", {
        email: data.email.trim(),
        password: data.password,
      });

      setIsLoading(false);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="container">
      <div className="Signup container ">
        <div className="row px-0 px-md-5 mx-md-5 ">
          <div className="col-12">
            <div className="d-flex flex-column justify-content-center align-items-center py-3">
              <h2 className="my-3 align-self-center">Regístrate</h2>
              <p className="mt-3">
                Crea tu cuenta de forma rápida a través de Facebook o Google, de
                esta forma podemos ubicarte de una mejor manera.
              </p>
              <FacebookLoginButton
                align="center"
                className="Facebook mt-4"
                style={{ backgroundColor: "rgb(66,133,244)" }}
                activeStyle={{ backgroundColor: "#4267B2" }}
                onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
              >
                <span>Facebook</span>
              </FacebookLoginButton>
              <GoogleLoginButton
                align="center"
                className="Google mt-2"
                onClick={() => Auth.federatedSignIn({ provider: "Google" })}
              ></GoogleLoginButton>
            </div>
          </div>
          <div className="col-12">
            <div className="container py-3">
              <hr data-content="ó" className="hr-text"></hr>
            </div>
            <div className="d-flex mx-0 mx-md-5  px-md-5 flex-column justify-content-center py-3">
              <h4 className="mt-2 mb-5 align-self-center text-secondary ">
                Cuenta con Correo
              </h4>
              <Form onSubmit={handleSubmit(handleSubmitForm)} className="mb-4">
                <TextBox
                  className="mb-4"
                  name="name"
                  placeholder="Nombre"
                  icon={faUser}
                  register={register(textBoxMaxLengthError(30))}
                  error={errors?.name}
                />
                <TextBox
                  name="lastname"
                  placeholder="Apellido"
                  icon={faHouseUser}
                  register={register(textBoxMaxLengthError(30))}
                  error={errors?.lastname}
                  className="mb-4"
                />
                <TextBox
                  name="email"
                  placeholder="Correo"
                  icon={faEnvelope}
                  register={register(textBoxMaxLengthError(100))}
                  error={errors?.email}
                  className="mb-4"
                />
                <TextBox
                  name="password"
                  placeholder="Contraseña"
                  icon={faLock}
                  type="password"
                  register={register(textBoxMaxLengthError(100))}
                  error={errors?.password}
                  className="mb-4"
                />
                <TextBox
                  name="confirmPassword"
                  placeholder="Repetir Contraseña"
                  icon={faLock}
                  type="password"
                  register={register({
                    ...textBoxMaxLengthError(100),
                    validate: (value) =>
                      value === watch("password") ||
                      "Las contraseñas deben coincidir",
                  })}
                  error={errors?.confirmPassword}
                  className="mb-5"
                />

                <LoaderButton
                  block
                  type="submit"
                  size="large"
                  isLoading={isLoading}
                >
                  Registrarse
                </LoaderButton>
              </Form>
            </div>
          </div>
          <div className="col-12 mt-5 mb-5">
            <NavLink
              className="text-right font-weight-light text-secondary"
              onClick={() => {
                history.push("/confirmar-cuenta", {
                  email: "",
                  password: "",
                });
              }}
            >
              <small>¿Ya creaste una cuenta?, confirmar código</small>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
