import React from "react";
import { Modal } from "react-bootstrap";
import LoginForm from "./LoginForm";

export default function LoginModal({
  showLoginModal,
  setShowLoginModal,
  isInModal,
}) {
  return (
    <Modal
      centered
      size="sm"
      show={showLoginModal}
      onHide={() => setShowLoginModal(false)}
    >
      <Modal.Body>
        <LoginForm
          isInModal={isInModal}
          setShowLoginModal={setShowLoginModal}
        />
      </Modal.Body>
    </Modal>
  );
}
