import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, Table } from "react-bootstrap";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { s3GetPublicFile } from "../libs/awsLib";
import { Image } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ShoppingBag.css";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import "./ShoppingSideBar.css";
import { Nav } from "react-bootstrap";

export default function ShoppingSideBar() {
  const history = useHistory();
  const { userInfo, setShowShoppingBag } = useAppContext();
  const [shoppingBag, setShoppingBag] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const pending_products_keys_list = JSON.parse(
      localStorage.getItem("pending_products_keys_list")
    );
    if (pending_products_keys_list && pending_products_keys_list?.length > 0) {
      addToShoppingBagAPI(pending_products_keys_list);
      localStorage.setItem("pending_products_keys_list", null);
    }

    callGetShoppingBagAPI();
  }, []);

  async function callGetShoppingBagAPI() {
    try {
      const responseBag = await API.get("virtualtech", `/shopping-bag`);
      const productList = responseBag.productsList;

      if (productList) {
        for (let i = 0; i < productList.length; i++) {
          const product = productList[i];
          try {
            let image = await s3GetPublicFile(
              product.mini_picture,
              product.creator_id
            );
            product.mini_picture = image;
          } catch (error) {
            console.log(error);
            console.log("error al obtener imágenes de cursos");
          }
        }
      }

      console.log("responseBag", responseBag);
      setShoppingBag(responseBag);
    } catch (error) {
      setShoppingBag(null);
      console.log("Couln't get shoppingBag");
    } finally {
      setIsLoading(false);
    }
  }

  async function addToShoppingBagAPI(products_keys_list) {
    try {
      await API.post("virtualtech", `/shopping-bag`, {
        body: {
          products_keys_list: products_keys_list,
          userData: userInfo,
        },
      });

      console.log("Item Added ! ");
    } catch (error) {
      console.log("Couln't add to shoppingBag");
    }
  }

  async function callRemoveAPI(complete_product_id) {
    setIsLoading(true);
    try {
      await API.del("virtualtech", `/shopping-bag/${complete_product_id}`);
      await callGetShoppingBagAPI();
    } catch (error) {
      console.log("error deleting from shopping bag", error);
    } finally {
      setIsLoading(false);
    }
  }

  function renderShoppingChart() {
    return (
      shoppingBag && (
        <div className={`my-5`}>
          <div className="Products container">
            {shoppingBag.productsList.map((product) => (
              <div className="row mb-4" key={product.product_id}>
                <div className="col-1">
                  <FontAwesomeIcon
                    style={{ color: "rgb(218, 218, 218)" }}
                    icon={faTimes}
                    onClick={() =>
                      callRemoveAPI(
                        product.product_id + "&" + product.creator_id
                      )
                    }
                  />
                </div>

                <div className="col-3 ">
                  <Image
                    src={product.mini_picture}
                    className=" img-fluid rounded shadow-sm"
                  />
                </div>

                <div
                  className="col-7 align-middle"
                  style={{ color: "rgb(218, 218, 218)" }}
                >
                  {product.name}
                </div>
              </div>
            ))}

            {shoppingBag.productsList.length < 2 && renderAddMessage()}
          </div>

          <LoaderButton
            className="mt-5"
            size="lg"
            block
            variant="light"
            onClick={() => {
              setShowShoppingBag(false);
              history.push("/completar-inscripcion");
            }}
          >
            Continuar
            <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
          </LoaderButton>
          <div className="d-flex flex-row justify-content-center">
            <Nav.Item
              style={{ color: "rgb(218, 218, 218)" }}
              className="mt-3"
              onClick={() => {
                setShowShoppingBag(false);
              }}
            >
              {/* <FontAwesomeIcon className="mr-2" size="sm" icon={faTimes} />{" "} */}
              Cerrar
            </Nav.Item>
          </div>
        </div>
      )
    );
  }

  function renderAddMessage() {
    return (
      <p
        className="text-center mt-5"
        style={{ color: "rgb(218, 218, 218)", fontSize: "80%" }}
      >
        Puedes agregar múltiples cursos
      </p>
    );
  }

  return (
    <div className="container pt-5 mt-4 pb-5 mb-5  ShoppingSideBar ">
      <h4 className="mx-1" style={{ color: "rgb(218, 218, 218)" }}>
        <strong> Inscripción </strong>{" "}
      </h4>
      {isLoading ? (
        <div className="container py-5 px-5 d-flex flex-row justify-content-center align-items-center">
          {" "}
          <Spinner animation="grow" variant="primary" />{" "}
        </div>
      ) : (
        <>
          {shoppingBag ? (
            <>
              <div className="mx-md-2">
                <div>{renderShoppingChart()}</div>
              </div>
            </>
          ) : (
            <>{renderAddMessage()}</>
          )}
        </>
      )}
    </div>
  );
}
