import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup } from "react-bootstrap";
import es from "date-fns/locale/es";
import { Controller } from "react-hook-form";
registerLocale("es", es);

export function DatePicker({
  control,
  name,
  rules,
  error = null,
  label = null,
  icon = null,
  text_muted = null,
  className = "",
  position_text_muted = "top",
  containerClassName = "",
  defaultValue = null,
  ...rest
}) {
  return (
    <FormGroup className={containerClassName}>
      {label && <Form.Label>{label}</Form.Label>}

      {text_muted && position_text_muted === "top" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}

      <div className="d-flex  ">
        {icon && (
          <InputGroup.Prepend>
            <InputGroup.Text className=" bg-transparent  mr-2">
              <FontAwesomeIcon icon={icon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}

        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, value }) => (
            <ReactDatePicker
              selected={value}
              onChange={(date) => onChange(date)}
              locale="es"
              wrapperClassName="flex-fill  flex-grow-1"
              className={`  form-control form-control-lg ${
                icon ? "border-left-0  " : ""
              } ${className}`}
              {...rest}
            />
          )}
        />
      </div>

      {error && <p className="text-info">{error?.message}</p>}
      {text_muted && position_text_muted === "down" && (
        <Form.Text className="text-muted mb-3">{text_muted}</Form.Text>
      )}
    </FormGroup>
  );
}
