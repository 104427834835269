import {
  faUser,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { TextBox } from "./FormComponents";
import { useForm } from "react-hook-form";
import { textBoxMaxLengthError } from "../messages/FieldsErrors";
import LoaderButton from "./LoaderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "aws-amplify";

export default function EmailForm({ onSuccessCallBack }) {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function sendEmailInfo(data) {
    console.log(data);

    setIsLoading(true);

    try {
      const response = await API.post("virtualtech", "/emails-nl", {
        body: data,
      });
      console.log("response: ", response);
      onSuccessCallBack();
    } catch (error) {
      console.log("error al guardar email", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <h4 className="mt-3">Mantente al día con nuestro newsletter</h4>
      <TextBox
        name="name"
        className="mt-3"
        placeholder="Nombre"
        icon={faUser}
        register={register(textBoxMaxLengthError(60))}
        error={errors?.name}
      />

      <TextBox
        name="email"
        className="mt-3"
        placeholder="Correo"
        icon={faEnvelope}
        register={register(textBoxMaxLengthError(100))}
        error={errors?.email}
      />

      <div className="mt-4 d-flex flex-row-reverse ">
        <LoaderButton
          className="btn btn-success"
          onClick={handleSubmit(sendEmailInfo)}
          isLoading={isLoading}
        >
          <FontAwesomeIcon className="mr-3" icon={faPaperPlane} /> Enviar
        </LoaderButton>
      </div>
    </div>
  );
}
