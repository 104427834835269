import React, { useEffect, useState, useRef } from "react";
import ReactPixel from "react-facebook-pixel";
import { API } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, Table } from "react-bootstrap";
import {
  faAddressCard,
  faCalendarAlt,
  faChevronRight,
  faCreditCard,
  faFingerprint,
  faInfoCircle,
  faLock,
  faMap,
  faMapMarkerAlt,
  faMapPin,
  faPhoneAlt,
  faTimes,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { s3GetPublicFile } from "../libs/awsLib";
import { Image, Tab } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {
  ControlledTextBox,
  SelectDropDown,
  TextBox,
} from "../components/FormComponents";
import { useForm, Controller } from "react-hook-form";
import {
  CVVError,
  requiredError,
  requiredNotEmptyError,
  textBoxMaxLengthError,
  positiveNumberError,
} from "../messages/FieldsErrors";
import {
  CountrySelectMenu,
  RegionSelectMenu,
} from "../components/CountryRegionsDropwdowns";
import { Nav } from "react-bootstrap";
import "./ShoppingBag.css";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "../components/DatePicker";
import { subYears, addYears } from "date-fns";
import { Link, useHistory } from "react-router-dom";
import publicIp from "public-ip";
import { Form } from "react-bootstrap";
import { FormGroup } from "react-bootstrap";
import { formatDateTimeWithoutTimeZone } from "../libs/utilsLib";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { fbPixelInitiateCheckout } from "../libs/facebookPixelLib";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";

export default function ShoppingBag() {
  const history = useHistory();
  const { userInfo } = useAppContext();
  const [shoppingBag, setShoppingBag] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSellInfoChart, setShowSellInfoChart] = useState(true);
  const {
    register,
    handleSubmit,
    formState,
    errors,
    control,
    getValues,
    reset,
  } = useForm({});
  const [selectedPayment, setSelectedPayment] = useState("credit-card");
  const [selectedCountry, setSelectedCountry] = useState("GT");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const captchaRef = useRef();
  const { isDark } = useAppContext();

  useEffect(() => {
    const pending_products_keys_list = JSON.parse(
      localStorage.getItem("pending_products_keys_list")
    );
    if (pending_products_keys_list && pending_products_keys_list?.length > 0) {
      addToShoppingBagAPI(pending_products_keys_list);
      localStorage.setItem("pending_products_keys_list", null);
    }

    callGetShoppingBagAPI();
  }, []);

  async function callGetShoppingBagAPI() {
    try {
      const responseBag = await API.get("virtualtech", `/shopping-bag`);
      const productList = responseBag.productsList;

      if (productList) {
        let content_ids = [];
        for (let i = 0; i < productList.length; i++) {
          const product = productList[i];
          content_ids.push(product.product_id);
          try {
            let image = await s3GetPublicFile(
              product.mini_picture,
              product.creator_id
            );
            product.mini_picture = image;
          } catch (error) {
            console.log(error);
            console.log("error al obtener imágenes de cursos");
          }
        }
        fbPixelInitiateCheckout(content_ids);
      }

      setShoppingBag(responseBag);
    } catch (error) {
      setShoppingBag(null);
      console.log("Couln't get shoppingBag");
    } finally {
      setIsLoading(false);
    }
  }

  async function addToShoppingBagAPI(products_keys_list) {
    try {
      await API.post("virtualtech", `/shopping-bag`, {
        body: {
          products_keys_list: products_keys_list,
          userData: userInfo,
        },
      });
    } catch (error) {
      console.log("Couln't add to shoppingBag");
    }
  }

  async function callRemoveAPI(complete_product_id) {
    setIsLoading(true);
    try {
      await API.del("virtualtech", `/shopping-bag/${complete_product_id}`);
      await callGetShoppingBagAPI();
    } catch (error) {
      console.log("error deleting from shopping bag", error);
    } finally {
      setIsLoading(false);
    }
  }

  function renderShoppingChart() {
    return (
      shoppingBag && (
        <div className={`container ${showSellInfoChart ? "mt-4" : "mt-5"} `}>
          {!showSellInfoChart && <h3 className="mb-3">Canasta</h3>}

          <Table responsive>
            <thead>
              <tr>
                <th scope="col" className=" border-top-0">
                  <div className="text-uppercase py-1 px-0 px-md-1  align-middle">
                    Cursos
                  </div>
                </th>
                <th scope="col" className=" border-top-0">
                  <div className="text-uppercase py-1 px-0 px-md-1  align-middle">
                    Precio
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {shoppingBag.productsList
                .sort((a, b) => (a?.category > b?.category ? 1 : -1))
                .map((product) => (
                  <tr key={product.product_id}>
                    <th scope="row">
                      <div className="row py-1 px-0 px-md-1">
                        {/* <div className="col-2 col-md-1">
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() =>
                              callRemoveAPI(
                                product.product_id + "&" + product.creator_id
                              )
                            }
                          />
                        </div> */}
                        <div className="col-12 col-md-12">
                          <Image
                            width="56"
                            height="56"
                            src={product.mini_picture}
                            className="img-fluid rounded shadow-sm"
                          />
                          <div className="ml-3 d-inline-block align-middle">
                            {product.name}
                          </div>
                        </div>
                      </div>
                    </th>
                    <td>
                      <div className="d-flex flex-row py-1 px-0 px-md-1 ">
                        <div className="align-middle">
                          {"Q " + product.price}
                        </div>
                        {product?.worst_price && (
                          <div
                            className="pl-3 align-middle"
                            style={{
                              textDecoration: "line-through",
                              fontSize: "87%",
                            }}
                          >
                            {"Q " + product?.worst_price}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="row mt-2 ">
            <div className="col-md-6">
              {shoppingBag.couponsApplied &&
                shoppingBag.couponsApplied.length > 0 && (
                  <div className="mt-2">
                    <h5 className="mb-3">Ofertas Aplicadas</h5>
                    {shoppingBag.couponsApplied.map((coupon, index) => (
                      <p className="mb-3 ml-md-3" key={index}>
                        {coupon.coupon_name}
                      </p>
                    ))}
                  </div>
                )}
            </div>
            <div className="col-md-6">
              <ul className="list-unstyled mb-1 mx-5 mt-3 mt-md-0">
                {shoppingBag?.worst_total !== shoppingBag?.total && (
                  <>
                    <li className="d-flex justify-content-between mb-1  ">
                      <p className="text-muted pb-0 mb-0">Antes</p>
                      <p
                        className="pb-0 mb-0"
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        {" "}
                        {"Q " + shoppingBag.worst_total}
                      </p>
                    </li>
                    <li className="d-flex justify-content-between mb-1 ">
                      <p className="text-success">Ahorras</p>
                      <p className="text-success">
                        {`- Q ${shoppingBag.worst_total - shoppingBag.total}`}
                      </p>
                    </li>
                  </>
                )}

                <li className="d-flex justify-content-between mb-2 ">
                  <h4 className="font-weight-bold"> Total</h4>
                  <h4 className="font-weight-bold">
                    {"Q " + shoppingBag.total}
                  </h4>
                </li>
              </ul>
              {!showSellInfoChart && (
                <LoaderButton
                  size="lg"
                  block
                  onClick={() => setShowSellInfoChart(true)}
                >
                  Continuar
                  <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
                </LoaderButton>
              )}
            </div>
          </div>
        </div>
      )
    );
  }

  function renderSellInfoChart() {
    return (
      <div className="mt-2 text-break">
        <h3>Datos del Estudiante</h3>
        <div className="row mt-5 mx-md-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col-6">
                <p>
                  <strong>Nombre </strong>
                </p>
              </div>
              <div className="col-6">{userInfo.name}</div>
            </div>
            <div className="row">
              <div className="col-6">
                <p>
                  <strong>Apellidos </strong>
                </p>
              </div>
              <div className="col-6">{userInfo.lastname}</div>
            </div>
            <div className="row">
              <div className="col-6">
                <p>
                  <strong>Email </strong>
                </p>
              </div>
              <div className="col-6">{userInfo.email}</div>
            </div>

            {userInfo.provider && (
              <div className="row">
                <div className="col-6">
                  <p>
                    <strong>Autenticación </strong>
                  </p>
                </div>
                <div className="col-6">{userInfo.provider}</div>
              </div>
            )}
          </div>
          <div className="col-md-6 d-flex align-items-end">
            {/* <SelectDropDown
              className="mx-md-3"
              label="Facultad a la cual deseas ingresar / estudias"
              name="career"
              register={register(requiredError)}
              error={errors?.career}
              optionsObjArray={[
                { label: "Ciencias Médicas", value: "Ciencias Médicas" },
                { label: "Ingeniería", value: "Ingeniería" },
                { label: "Arquitectura", value: "Arquitectura" },
                {
                  label: "Ciencias Jurídicas y Sociales",
                  value: "Ciencias Jurídicas y Sociales",
                },
                { label: "Ciencias Económicas", value: "Ciencias Económicas" },
                { label: "Química y Farmacia", value: "Química y Farmacia" },
                { label: "Odontología", value: "Odontología" },
                { label: "Veterinaria", value: "Veterinaria" },
                { label: "Agronomía", value: "Agronomía" },
                { label: "Ciencias Políticas", value: "Ciencias Políticas" },
                { label: "Enfermería", value: "Enfermería" },
                {
                  label: "Ciencias Físicas y Matemáticas",
                  value: "Ciencias Físicas y Matemáticas",
                },
                { label: "Otro", value: "OTro" },
              ]}
            /> */}
            <p className="text-muted mb-0 pb-0">
              Se tendrá acceso a los cursos a través de esta cuenta.
            </p>
          </div>
        </div>
        <h3 className="mt-5">Información de Pago</h3>
        <div className=" mt-5 mx-md-0">
          <div className="row ">
            <div className="col-md-6">
              <CountrySelectMenu
                control={control}
                name="country"
                error={errors?.country}
                rules={requiredError}
                label="País"
                classes="form-control"
                containerClassName="mx-md-3"
                valueType="short"
                showDefaultOption
                defaultValue={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                priorityOptions={["GT"]}
              />
            </div>
            <div className="col-md-6">
              <RegionSelectMenu
                control={control}
                name="state"
                error={errors?.state}
                rules={requiredNotEmptyError}
                label="Departamento"
                classes="form-control"
                containerClassName="mx-md-3"
                countryValueType="short"
                defaultOptionLabel=" - selecciona -"
                country={selectedCountry}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <TextBox
                className="mx-md-3"
                label="Ciudad / Municipio"
                name="city"
                register={register(textBoxMaxLengthError(100))}
                error={errors?.city}
                icon={faMap}
              />
            </div>
            <div className="col-md-6">
              <TextBox
                className="mx-md-3"
                label="Dirección"
                name="street1"
                error={errors?.street1}
                register={register(textBoxMaxLengthError(100))}
                placeholder={""}
                icon={faMapMarkerAlt}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="d-flex flex-row justify-content-start">
                <div className="col-11 pl-0 pr-0">
                  <TextBox
                    className="mx-md-3"
                    label="Código Postal"
                    name="postalCode"
                    register={register(textBoxMaxLengthError(50))}
                    error={errors?.postalCode}
                    icon={faMapPin}
                  />
                </div>
                <div className="col-1 d-flex flex-column justify-content-center align-items-start">
                  <a
                    href="https://mapanet.eu/Postal_Codes/?C=GT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <TextBox
                type="number"
                className="mx-md-3"
                label="Teléfono"
                name="phone"
                register={register(positiveNumberError)}
                error={errors?.phone}
                placeholder={""}
                icon={faPhoneAlt}
              />
            </div>
          </div>
          <div className="mt-4">
            <Tab.Container defaultActiveKey="credit-card">
              <div>
                <Nav variant="pills" className="d-flex ">
                  <Nav.Item
                    className="w-50"
                    onClick={() => setSelectedPayment("credit-card")}
                  >
                    <Nav.Link eventKey="credit-card">
                      <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                      Tarjeta
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className="w-50"
                    onClick={() => setSelectedPayment("bank")}
                  >
                    <Nav.Link eventKey="bank">
                      <FontAwesomeIcon icon={faUniversity} className="mr-1" />
                      Depósito Bancario
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div>
                <Tab.Content>{decideFormToRender()}</Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    );
  }

  function decideFormToRender() {
    switch (selectedPayment) {
      case "credit-card":
        return (
          <Tab.Pane eventKey="credit-card">{renderCreditCardForm()}</Tab.Pane>
        );
      case "bank":
        return <Tab.Pane eventKey="bank">{renderBankDepositForm()}</Tab.Pane>;
      default:
        return <></>;
    }
  }

  function renderBankDepositForm() {
    return (
      <div className="row mx-md-2 mt-4">
        <div className="col-md-6 ">
          <p className="text-justify mt-3 pr-md-3  ">
            {" "}
            Si deseas, puedes pagar el monto de inscripción realizando un
            depósito bancario a nuestro número de cuenta. Puedes hacerlo a
            través de banca en linea o en una agencia.
            <br></br>
          </p>
          <p className="mt-3 text-justify pr-md-3 ">
            Posteriormente debes enviarnos los detalles de la transacción
            realizada y el comprobante en la sección de "Ordenes" para agregarte
            a los cursos y completar tu inscripción.
          </p>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-end">
          <div className="row">
            <div className="col-6">
              <strong>Banco</strong>
            </div>
            <div className="col-6">Banrural</div>
          </div>
          <div className="row">
            <div className="col-6">
              <strong>Tipo de Cuenta</strong>
            </div>
            <div className="col-6">Monetaria</div>
          </div>
          <div className="row">
            <div className="col-6">
              <strong>Número de Cuenta</strong>
            </div>
            <div className="col-6">03103200050618</div>
          </div>
          <div className="row">
            <div className="col-6">
              <strong>A Nombre de </strong>
            </div>
            <div className="col-6">Virtual Tech GT Tutorías </div>
          </div>

          <div className="d-flex flex-row justify-content-center mt-5 ">
            <ReCAPTCHA
              sitekey="6Lcv9yUaAAAAACXbBXm4PQwDEq4skEPsRfxy2gYB"
              ref={captchaRef}
              onChange={onRecaptchaChange}
              theme={isDark ? "dark" : "light"}
            />
          </div>

          <LoaderButton
            variant="success"
            size="lg"
            className="ml-md-3 mt-5 text-capitalize"
            onClick={handleSubmit(callCreateBankOrderAPI)}
            isLoading={isButtonLoading}
            disabled={reCaptchaToken === null}
          >
            Generar Orden de Pago
          </LoaderButton>
        </div>
      </div>
    );
  }

  function getMonthsNumbers() {
    let monthsArray = [];
    for (let i = 1; i < 13; i++) {
      monthsArray.push({
        label: `${i < 10 ? "0" + i : i + ""}`,
        value: `${i < 10 ? "0" + i : i + ""}`,
      });
    }
    return monthsArray;
  }

  function getYearsNumbers() {
    let yearsArray = [];
    const actualYear = new Date().getFullYear();
    for (let i = actualYear; i < actualYear + 15; i++) {
      yearsArray.push({ label: `${i}`, value: `${i}` });
    }
    return yearsArray;
  }

  function renderCreditCardForm() {
    return (
      <div className="row mx-md-2">
        <div className="col-md-7">
          <p className="mb-4 mt-4" style={{ fontSize: "90%" }}>
            Se aceptan tarjetas de crédito y débito
          </p>
          <TextBox
            className="mx-md-3"
            label="Nombre en la tarjeta"
            name="nameCard"
            register={register(textBoxMaxLengthError(200))}
            error={errors?.nameCard}
            icon={faAddressCard}
          />
          <Controller
            control={control}
            name="accountNumber"
            rules={{
              ...textBoxMaxLengthError(19),
              minLength: { value: 19, message: `Mínimo ${16} dígitos` },
              validate: {
                luhn: (value) =>
                  isValidLuhnAlgorithm(value) ||
                  "Número de tarjeta incorrecto.",
                isInt: (value) =>
                  parseInt(value.replace(/\s/g, "")) ||
                  "Debe ingresar un número.",
              },
            }}
            defaultValue={""}
            render={({ onChange, onBlur, value }) => (
              <ControlledTextBox
                formControlProps={{
                  value: value ? value : "",
                  onChange: (event) =>
                    onChange(formatCreditCard(event.target.value)),
                }}
                className="mx-md-3"
                label="Número de tarjeta"
                type="tel"
                placeholder="0000 0000 0000 0000"
                error={errors?.accountNumber}
                icon={faCreditCard}
              />
            )}
          />

          <div className="row  mx-md-1">
            <div className="col-md-7">
              <FormGroup>
                <Form.Label>Fecha de Vencimiento</Form.Label>
                <div className="row">
                  <div className="col-6 col-md-6">
                    <SelectDropDown
                      placeholder="MM"
                      name="expirationMonth"
                      register={register(requiredNotEmptyError)}
                      error={errors?.expirationMonth}
                      icon={faCalendarAlt}
                      optionsObjArray={getMonthsNumbers()}
                    />
                  </div>
                  <div className="col-6 col-md-6">
                    <SelectDropDown
                      className="pl-0 ml-0"
                      placeholder="YY"
                      name="expirationYear"
                      register={register(requiredNotEmptyError)}
                      error={errors?.expirationYear}
                      optionsObjArray={getYearsNumbers()}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>

            <div className="col-md-5">
              <TextBox
                icon={faFingerprint}
                label="Código de seguridad"
                type="number"
                name="CVVCard"
                placeholder="CVV"
                register={register(CVVError)}
                error={errors?.CVVcard}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 d-flex flex-column justify-content-end">
          <div className="d-flex flex-row justify-content-center mt-3 mt-md-1 ">
            <ReCAPTCHA
              sitekey="6Lcv9yUaAAAAACXbBXm4PQwDEq4skEPsRfxy2gYB"
              ref={captchaRef}
              onChange={onRecaptchaChange}
              theme={isDark ? "dark" : "light"}
            />
          </div>

          <LoaderButton
            variant="success"
            size="lg"
            className="ml-md-3 mt-4 mt-md-5 text-capitalize"
            onClick={handleSubmit(handleCreditCardPayment)}
            isLoading={isButtonLoading}
            disabled={reCaptchaToken === null}
          >
            Completar Inscripción
          </LoaderButton>
          <div className="d-flex flex-row mt-4 text-secondary align-items-center">
            <FontAwesomeIcon
              icon={faCcVisa}
              className="ml-md-3 mr-2"
              size="2x"
            />

            <FontAwesomeIcon icon={faCcMastercard} className="mr-3" size="2x" />
            <FontAwesomeIcon icon={faLock} className="mr-2 ml-auto" size="2x" />
            <p className="align-self-end mb-0" style={{ lineHeight: "90%" }}>
              <small>
                Conexión <br></br> Segura
              </small>
            </p>
          </div>
        </div>
      </div>
    );
  }

  async function callCreateBankOrderAPI(data) {
    setIsButtonLoading(true);
    try {
      await API.post("virtualtech", `/orders/create-bank-order`, {
        body: {
          userData: userInfo,
          data,
        },
      });
      setIsButtonLoading(false);
      toast.success("Orden generada exitosamente");
      history.push("/mis-ordenes");
    } catch (error) {
      toast.error("Ocurrió un error, no se pudo generar la orden");
      setIsButtonLoading(false);
      console.log("error in creating bank order", error);
    }
  }

  function onRecaptchaChange(token) {
    setReCaptchaToken(token);
  }

  async function handleCreditCardPayment(data) {
    setIsButtonLoading(true);
    const client_ip = await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });
    captchaRef.current.reset();

    data = {
      ...data,
      accountNumber: data.accountNumber.replace(/\s/g, ""),
      fecha_transaccion: formatDateTimeWithoutTimeZone(new Date()),
      client_ip,
      reCaptchaToken,
    };
    try {
      const body = {
        userData: userInfo,
        data,
      };
      await API.post("virtualtech", `/transactions/credit-card-payment`, {
        body,
      });
      toast.success("🚀 Inscripción Exitosa !");
      setIsButtonLoading(false);
      try {
        ReactPixel.track("Purchase", {
          currency: "GTQ",
          value: shoppingBag?.total,
        });
      } catch (error) {
        console.log("error on fb react pixel");
      }
      history.push("/mis-cursos");
    } catch (error) {
      toast.error("No fue posible realizar el pago e inscripción");
      setIsButtonLoading(false);
      console.log("error in credit card payment", error);
    }
  }

  function formatCreditCard(value) {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{4,16}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }

  function isValidLuhnAlgorithm(value) {
    if (/[^0-9-\s]+/.test(value)) return false;
    let nCheck = 0,
      bEven = false;
    value = value.replace(/\D/g, "");
    for (let n = value.length - 1; n >= 0; n--) {
      let cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 == 0;
  }

  return (
    <div className="container pt-4 pb-5 mb-5 ShoppingBag ">
      <h1>Inscripción</h1>
      {isLoading ? (
        <div className="container py-5 px-5 d-flex flex-row justify-content-center align-items-center">
          {" "}
          <Spinner animation="grow" variant="primary" />{" "}
        </div>
      ) : (
        <>
          {shoppingBag ? (
            <>
              <div className="mx-md-3">
                <div>{renderShoppingChart()}</div>
                <div>{showSellInfoChart && renderSellInfoChart()}</div>
              </div>
            </>
          ) : (
            <p className="py-5 px-md-5">Canasta vacía </p>
          )}
        </>
      )}
    </div>
  );
}
