export const textBox50Error = {
  required: { value: true, message: "Campo Requerido" },
  maxLength: { value: 50, message: "Máximo 50 caracteres" },
};

export const textBoxNotRequiredMaxLength = (maxLength) => {
  return {
    required: { value: false, message: "Campo Requerido" },
    maxLength: { value: maxLength, message: `Máximo ${maxLength} caracteres` },
  };
};

export const textBoxMaxLengthError = (maxLength) => {
  return {
    required: { value: true, message: "Campo Requerido" },
    maxLength: { value: maxLength, message: `Máximo ${maxLength} caracteres` },
  };
};

export const textBoxMaxLengthNotRequiredError = (maxLength) => {
  return {
    maxLength: { value: maxLength, message: `Máximo ${maxLength} caracteres` },
  };
};

export const textBoxMaxLenghtConditionalRequired = (maxLength, condition) => {
  return {
    required: {
      value: condition,
      message:
        "El monto depositado no coincide con el total de la orden, debe escribir una aclaración.",
    },
    maxLength: { value: maxLength, message: `Máximo ${maxLength} caracteres` },
  };
};

export const requiredError = {
  required: { value: true, message: "Campo Requerido" },
};
export const requiredNotEmptyError = {
  required: { value: true, message: "Campo Requerido" },
  minLength: { value: 1, message: "Campo Requerido" },
};

export const CVVError = {
  required: { value: true, message: "Campo Requerido" },
  validate: {
    positive: (value) =>
      parseInt(value) > 0 || "Debe ser un número mayor a cero",
    lessThan: (value) =>
      value.length <= 4 || "Debe tener como máximo 4 dígitos",
    graderThan: (value) =>
      value.length >= 3 || "Debe tener como mínimo 3 dígitos",
  },
};

export const positiveNumberError = {
  required: { value: true, message: "Campo Requerido" },
  validate: {
    positive: (value) => parseInt(value) > 0 || "Debe ser un número positivo",
  },
  valueAsNumber: true,
};

export const nonNegativeNumberError = {
  required: { value: true, message: "Campo Requerido" },
  validate: {
    positive: (value) =>
      parseInt(value) >= 0 || "Debe ser un número no negativo",
  },
  valueAsNumber: true,
};
