import React from "react";
import { Card } from "react-bootstrap";
import "./CardButton.css";

export default function CardButton({ children, className, ...props }) {
  return (
    <Card
      {...props}
      style={{
        cursor: "pointer",
      }}
      className={`CardButton ${className} p-0 `}
    >
      {/* <div style={{ cursor: "pointer" }} className="CardButton p-0 clickable "> */}
      {children}
      {/* </div> */}
    </Card>
  );
}
