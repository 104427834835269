import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Spinner, Card, CardDeck } from "react-bootstrap";
import StarRatingComponent from "react-star-rating-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import CardButton from "../components/CardButton";
import { useHistory } from "react-router-dom";
import { s3GetPublicFile } from "../libs/awsLib";

export default function Courses() {
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function onLoad() {
      try {
        const courses = await API.get("virtualtech", "/products");

        for (let i = 0; i < courses.length; i++) {
          const course = courses[i];
          try {
            let image = await s3GetPublicFile(
              course.mini_picture,
              course.creator_id
            );
            course.mini_picture = image;
          } catch (error) {
            console.log(error);
            console.log("error al obtener imágenes de cursos");
          }
        }

        const orderedCourses = courses.sort((a, b) => {
          // let resultResponses = 0;
          // if (a?.category < b?.category) {
          //   resultResponses = -1;
          // }
          // if (a?.category > b?.categoy) {
          //   resultResponses = 1;
          // }
          let resultDate = 0;
          if (a?.students_count < b?.students_count) {
            resultDate = 1;
          }
          if (a?.students_count > b?.students_count) {
            resultDate = -1;
          }
          return resultDate;
        });
        console.log("ordered courses", orderedCourses);

        setCourses(orderedCourses);
        setIsLoading(false);
      } catch (error) {
        console.log("Error en Product Courses List");
        console.log(error);
        setIsLoading(false);
      }
    }

    onLoad();
    window.scrollTo(0, 0);
  }, []);

  function renderProducts() {
    return courses.map((course, i) => (
      <div
        className={`col-md-4  mb-5  mt-1 mt-md-0 mx-2 mx-md-0  px-md-4   `}
        key={course.product_id}
      >
        <CardButton
          className="h-100 rounded-lg"
          onClick={() => {
            history.push(`/cursos/${course.product_id}`);
          }}
        >
          {course.mini_picture && (
            <Card.Img
              variant="top"
              src={course.mini_picture}
              className="rounded-top-lg"
            />
          )}
          <Card.Body>
            <Card.Title as="h4">{course.name}</Card.Title>
            {/* <Card.Text>{course.description}</Card.Text> */}
          </Card.Body>
          <div className="row px-md-3  pb-2 ">
            <div className="col-md-7 col-6">
              {course.stars > 3 && (
                <div className="d-flex flex-row justify-content-center justify-content-md-start ">
                  <p className="text-center ml-md-2">
                    <strong className="text-center">
                      {course.stars + "   "}
                    </strong>{" "}
                  </p>
                  <StarRatingComponent
                    className="text-center pl-0  pl-md-1"
                    name="ratio1"
                    editing={false}
                    starCount={5}
                    value={Math.round(course.stars)}
                  />
                </div>
              )}
            </div>
            <div className="col-md-5 col-6">
              {course.students_count > 5 && (
                <div className="d-flex flex-row justify-content-center justify-content-md-end ">
                  <p className="text-md-right text-sm-center pr-md-2">
                    <small>
                      {" "}
                      <strong>{course.students_count + "+   "}</strong>
                      <FontAwesomeIcon icon={faUserGraduate} />
                    </small>
                  </p>
                </div>
              )}
            </div>
          </div>
        </CardButton>
      </div>
    ));
  }

  return (
    <div className="container pb-5 mb-5">
      <h1 className="mb-5 pt-5 ">Cursos</h1>
      {isLoading && (
        <div className="container py-5 px-5 d-flex flex-row justify-content-center align-items-center">
          {" "}
          <Spinner animation="grow" variant="primary" />{" "}
        </div>
      )}
      <div className="px-md-4">
        <div className="row">{!isLoading && renderProducts()}</div>
      </div>
    </div>
  );
}
